import React from 'react'; //import React Component
import { Navigate, Route, Routes } from "react-router-dom";
import { Main } from './Pages/Main/Main'
import { Research } from './Pages/Research/Research';
import { Employment } from './Pages/Employment/Employment';
import { Projects } from './Pages/Projects/Projects';
import { ProjectDetail } from './Pages/Projects/ProjectDetail';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Navigate to="main" />} />
        <Route path="*" element={<Navigate to="main" />} />
        <Route path="main" element={<Main />} />
        <Route path="Research" element={<Research />} />
        <Route path="Employment" element={<Employment />} />
        <Route path="Projects" element={<Projects />} />
        <Route path="/Projects/:name" element={<ProjectDetail />} />
      </Routes>
    </div>
  );
}

export default App;
