import React, { useEffect, useRef, useState } from 'react'; //import React Component
import { useParams } from 'react-router-dom';
import styles from './ProjectDetail.module.css';
import { Bg } from '../../Components/Bg/Bg.js';
import { Footer } from '../../Components/Footer/Footer.js';
import { MenuS } from '../../Components/Menu/MenuS.js';
import { ImgGallery } from '../../Components/ImgGallery/ImgGallery.js'
import projectsIntroList from '../../Data/projectsInfo.json';

export function ProjectDetail() {
    const topFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };

    //屏幕不在最顶显示回到顶部
    const toTopRef = useRef(null)
    useEffect(() => {
        const handleScroll = () => {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                toTopRef.current.style.opacity = "1";
                toTopRef.current.style.pointerEvents = "all";
            } else {
                toTopRef.current.style.opacity = "0";
                toTopRef.current.style.pointerEvents = "none";
            }
        };
        window.addEventListener("scroll", handleScroll);
        // 在组件卸载时清除事件监听器
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    //----------
    //确定project
    //----------
    //----------
    const [name, setName] = useState("")
    const [idName, setIdName] = useState("")
    const params = useParams();
    useEffect(() => {
        const rawNameString = params.name;
        setIdName((rawNameString).replace('%20', " ").replace('@', "%40"))
        setName((rawNameString).replace('%20', " ").replace('@', "%40").split(":")[0]);
    }, [params.name])

    const [pageData, setPageData] = useState(null)
    useEffect(() => {
        let data = projectsIntroList.filter((data) => {
            return data.name === idName
        })
        setPageData(data[0])
    }, [idName])

    //获取信息
    const [device, setDevice] = useState(null)
    const [technique, setTechnique] = useState(null)
    const [timeLine, setTimeLine] = useState(null)
    const [team, setTeam] = useState(null)
    const [img, setImg] = useState([])
    const [intro, setIntro] = useState([])
    const [logo, setLogo] = useState([])
    const [highLightFeature, setHighLightFeature] = useState([])
    const [featureDiv, setFeatureDiv] = useState([])
    const [url, setUrl] = useState([])
    useEffect(() => {
        if (pageData) {
            setDevice(pageData.bestDevice);
            setTechnique(pageData.technique);
            setTimeLine(pageData.timeLine);
            setTeam(pageData.team);
            setImg(pageData.img)
            setIntro(pageData.intro)
            setLogo(pageData.logo)
            setHighLightFeature(pageData.highLightFeature)
            setUrl(pageData.url)
        }
    }, [pageData])

    useEffect(() => {
        let featureDiv = highLightFeature.map((feature) => {
            return (
                <li key={feature}>{feature}</li>
            )
        })
        setFeatureDiv(featureDiv)
    }, [highLightFeature])

    //切换页面
    const mainRef = useRef(null)
    const titleRef = useRef(null)
    const decorateRef = useRef(null)
    const changePage = (e) => {
        e.stopPropagation();
        let { value } = e.target.dataset
        mainRef.current.style.opacity = "0"
        decorateRef.current.style.opacity = "0"
        titleRef.current.style.transform = "translateX(-50%) translateY(-200px)"
        setTimeout(() => {
            topFunction()
            if (value.includes("https")) {
                window.location.href = `${value}`
            } else {
                window.location.href = `/${value}`
            }

        }, 400);
    }

    return (
        <div id={styles.ProjectDetail} >
            <div className={styles.title} ref={titleRef}>
                <h1>
                    {name}
                </h1>
            </div>
            <div className={styles.goBack} onClick={(e) => changePage(e)} data-value={"Projects"}>&lt; BACK</div>
            <div className={styles.toTop} onClick={topFunction} ref={toTopRef}>
                <svg t="1722106080757" className={styles.icon} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5574">
                    <path d="M76.83347 382.604066L404.834786 54.650852c56.89557-56.887553 157.619247-56.791351 214.434647 0.032068l327.905114 327.93718c59.180371 59.236489 59.180371 155.230226-0.016034 214.434648-59.252523 59.140287-155.190142 59.188388-214.410597-0.032068L663.666737 527.909435v332.386531c0 83.735976-67.894685 151.614628-151.622644 151.614627-83.743993 0-151.590577-67.878651-151.590577-151.614627V527.8453l-69.217465 69.209448a151.326021 151.326021 0 0 1-107.209307 44.373253c-38.76146 0-77.603088-14.815135-107.201291-44.405321-59.188388-59.228472-59.188388-155.182125 0.008017-214.418614z" fill="#FFFFFF" p-id="5575"></path>
                    <path d="M510.087982 12.121474c40.060189-0.472994 80.288732 13.668726 109.173435 42.561446l327.905113 327.93718c59.180371 59.236489 59.180371 155.230226-0.016034 214.434648-59.252523 59.140287-155.190142 59.188388-214.410597-0.032068l-69.081179-69.113245v332.386531c0 83.735976-67.894685 151.614628-151.622644 151.614627-0.657382 0-1.290713-0.096202-1.948094-0.096202V12.121474z" fill="#E9F1F4" p-id="5576"></path>
                    <path d="M291.228034 597.054748a151.221802 151.221802 0 0 1-182.719998 24.050544l296.32675-296.294682c56.89557-56.855485 157.619247-56.791351 214.434647 0.048101l296.214514 296.246581c-57.96181 33.366121-133.215962 25.461509-182.736031-24.082612L663.666737 527.909435v332.386531c0 83.735976-67.894685 151.614628-151.622644 151.614627-83.743993 0-151.590577-67.878651-151.590577-151.614627V527.8453l-69.225482 69.209448z" fill="#D8E5EA" p-id="5577"></path>
                    <path d="M511.995992 282.20908v729.701513c-83.72796-0.016034-151.550493-67.894685-151.550493-151.614627V527.8453l-69.217465 69.209448a151.221802 151.221802 0 0 1-182.719998 24.050544l296.32675-296.294682c28.419726-28.403692 67.798483-42.60153 107.161206-42.60153z" fill="#E9F1F4" p-id="5578"></path>
                    <path d="M512.036076 1023.935865c-90.213589 0-163.615849-73.410276-163.615849-163.639899V556.874306l-48.694334 48.678301c-30.961067 30.896932-72.047412 47.900666-115.715183 47.900666a162.565642 162.565642 0 0 1-115.707166-47.932734c-63.749975-63.814109-63.741958-167.624273 0.016034-231.422348L396.32891 46.144977C425.662556 16.819347 467.82316 0 512.012025 0h0.072152c44.196883 0.016034 86.357486 16.851414 115.683115 46.177044l327.905113 327.93718c63.774025 63.830143 63.766008 167.65634-0.016033 231.438383-63.854194 63.741958-167.664357 63.70989-231.414332-0.032068l-48.558048-48.582098v303.349508c0 90.23764-73.410276 163.647916-163.647916 163.647916zM360.445499 515.820028a12.017255 12.017255 0 0 1 12.025272 12.025272v332.450666c0 76.969757 62.611582 139.589356 139.565305 139.589355 76.977774 0 139.597373-62.627616 139.597372-139.589355V527.909435a12.017255 12.017255 0 1 1 20.531148-8.505876l69.081178 69.113246c54.378279 54.370263 142.940398 54.394313 197.41488 0.02405 54.40233-54.40233 54.410347-142.972466 0.008017-197.422896L610.763558 63.180778c-24.852229-24.852229-60.823825-39.114201-98.695415-39.130234h-0.056118c-37.863573 0-73.819136 14.245939-98.679381 39.098167L85.339346 391.109942c-54.394313 54.42638-54.40233 142.988499-0.024051 197.41488a138.691469 138.691469 0 0 0 98.695415 40.885924c37.246275 0 72.311968-14.518512 98.719465-40.861874l69.209448-69.201431a12.025272 12.025272 0 0 1 8.505876-3.527413z" fill="#472968" p-id="5579" data-spm-anchor-id="a313x.search_index.0.i9.7c2b3a81wMgrnZ" ></path>
                    <path d="M664.516523 539.934707c-3.07847 0-6.156939-1.17046-8.505876-3.519397l-72.913231-72.86513a12.025272 12.025272 0 1 1 17.011751-17.011752l72.913232 72.873148a12.025272 12.025272 0 0 1-8.505876 20.523131zM359.467443 539.934707a12.033289 12.033289 0 0 1-8.505875-20.523131l72.897198-72.873148a12.041306 12.041306 0 0 1 17.011751 0 12.041306 12.041306 0 0 1 0 17.011752l-72.897198 72.86513a11.985188 11.985188 0 0 1-8.505876 3.519397z" fill="#33363A" p-id="5580"></path>
                    <path d="M550.12412 708.480917c-3.07847 0-6.156939-1.17046-8.505875-3.519396l-29.622253-29.63027-29.622253 29.63027a12.025272 12.025272 0 0 1-17.011752-17.003735l38.128129-38.144162c4.513485-4.513485 12.498266-4.513485 17.011751 0l38.128129 38.144162a12.033289 12.033289 0 0 1-8.505876 20.523131z" fill="#472968" p-id="5590" data-spm-anchor-id="a313x.search_index.0.i17.7c2b3a81wMgrnZ" className="selected"></path>
                    <path d="M511.987975 756.405634a12.025272 12.025272 0 0 1-12.025272-12.025272V658.311483a12.025272 12.025272 0 1 1 24.050544 0v86.068879a12.025272 12.025272 0 0 1-12.025272 12.025272zM511.995992 792.561618a12.017255 12.017255 0 0 1-12.025272-12.025272c0-3.206739 1.282696-6.253141 3.527413-8.505876 4.489435-4.489435 12.506283-4.489435 16.995717 0a12.025272 12.025272 0 0 1-8.497858 20.531148zM511.995992 828.637433a12.017255 12.017255 0 0 1-12.025272-12.025272c0-3.206739 1.282696-6.253141 3.527413-8.505875 4.489435-4.489435 12.506283-4.489435 16.995717 0a12.025272 12.025272 0 0 1-8.497858 20.531147zM511.995992 864.713249c-3.126571 0-6.253141-1.282696-8.497859-3.527413a12.001221 12.001221 0 0 1 0-17.003735c4.489435-4.489435 12.586451-4.489435 16.995717 0a12.025272 12.025272 0 0 1-8.497858 20.531148z" fill="#472968" p-id="5591" data-spm-anchor-id="a313x.search_index.0.i14.7c2b3a81wMgrnZ" className="selected"></path>
                </svg>
            </div>
            <div className={styles.bgFrame}></div>
            <main ref={mainRef}>
                <div className={styles.decorate} ref={decorateRef}>
                    <div></div>
                </div>
                <div className={styles.infoContent_container}>
                    <div className={styles.device}>
                        {
                            device === "phone" ?
                                <div className={`${styles.laptop} ${styles.phone}`}>
                                    <div className={styles.laptop_content}>
                                        <iframe src={url} frameBorder="0"></iframe>
                                    </div>
                                </div>
                                :
                                <div className={`${styles.laptop}`}>
                                    <div className={styles.laptop_content}>
                                        <iframe src={url} frameBorder="0"></iframe>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className={styles.subInfoContent_container}>
                        <div className={styles.imgGallery_container}>
                            <ImgGallery imgs={img} height={device === "phone" ? "40vh" : "auto"} width={device !== "phone" ? "40vh" : "auto"} />
                        </div>
                        <div className={styles.infoContent}>
                            <div className={styles.basic}>
                                <h2>Basic Information</h2>
                                <ul>
                                    <li><strong>Technique: </strong>{technique}</li>
                                    <li><strong>Timeline: </strong>{timeLine}</li>
                                    <li><strong>Team: </strong>{team}</li>
                                </ul>
                            </div>

                            <div className={styles.intro}>
                                <h2 style={{ gridColumn: "1/span 2", gridRow: "1" }}>Introduction</h2>
                                <div style={{ gridRow: "2", margin: "1rem 0" }}>{intro}</div>
                                <div>
                                    <img
                                        className={styles.forShake}
                                        style={{ width: "150px", height: "150px", borderRadius: "50%", border: "2px solid white", marginLeft: "10px" }}
                                        src={process.env.PUBLIC_URL + logo}
                                        alt="logo" />
                                </div>
                            </div>

                            <div className={styles.feature}>
                                <h2 style={{ gridColumn: "1/span 2", gridRow: "1" }}>HighLight Feature</h2>
                                <ul>
                                    {featureDiv}
                                </ul>
                            </div>

                            <div className={styles.url}>
                                Go to <span onClick={(e) => changePage(e)} data-value={url}><strong style={{ pointerEvents: "none" }}>{name}</strong></span>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>

            <MenuS changePage={(e) => changePage(e)} />
            <Bg />
        </div>
    )
}