import React, { useEffect, useRef, useState } from 'react'; //import React Component
import styles from './Footer.module.css'

export function Footer(props) {
    const footerRef = useRef(null)
    useEffect(() => {
        if (props.footerRef) {
            footerRef.current.style.opacity = "0"
            footerRef.current.style.pointerEvents = "none"
        }
    }, [props.footerRef])

    //click menu后设置部分变量
    const [menu, setMenu] = useState(false)
    const openMenu = () => {
        setMenu(!menu)
    }

    //click menu后部分动效
    const card_containerRef = useRef(null)
    useEffect(() => {
        if (menu) {
            footerRef.current.style.position = "fixed"
            card_containerRef.current.style.opacity = "1"
            card_containerRef.current.style.pointerEvents = "all"
        } else {
            if (!position) {
                footerRef.current.style.position = window.location.href.split("/")[window.location.href.split("/").length - 1] === "Employment" || window.location.href.split("/")[window.location.href.split("/").length - 1] === "Research" ? "relative" : "fixed"
            }
            card_containerRef.current.style.opacity = "0"
            card_containerRef.current.style.pointerEvents = "none"
        }
    }, [menu])

    //设置position(仅针对research页面)
    const [position, setPosition] = useState(false)
    useEffect(() => {
        setPosition(props.position)
    }, [props.position])

    useEffect(() => {
        if (position) {
            footerRef.current.style.position = "fixed"
        } else {
            footerRef.current.style.position = window.location.href.split("/")[window.location.href.split("/").length - 1] === "Employment" || window.location.href.split("/")[window.location.href.split("/").length - 1] === "Research" || window.location.href.includes("Projects/") ? "relative" : "fixed"
        }
    }, [position])

    return (
        <footer ref={footerRef}>
            <p>Fullstack Developer</p>
            <p onClick={openMenu}>&copy; Victoria</p>

            <div className={styles.card_container} ref={card_containerRef}>
                <div className={styles.front}></div>
                <div className={styles.back}></div>
            </div>
        </footer>
    );
}