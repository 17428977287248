import React, { useEffect, useRef, useState } from 'react'; //import React Component
import styles from './Menu.module.css';

export function MenuS(props) {
    const [path, setPath] = useState("")
    useEffect(() => {
        setPath(window.location.pathname.split("/")[1])
    }, [])

    const allPath = ["Research", "Projects", "Employment"]
    const [filterPath, setFilterPath] = useState([])
    useEffect(() => {
        let filterPath = allPath.filter((subPath) => { return subPath !== path })
        setFilterPath(filterPath)
    }, [path])

    //滑动降低
    const menuSRef = useRef(null)
    let scrollTimeout = useRef(null);
    useEffect(() => {
        const handleScroll = () => {
            if (menuSRef.current) {
                menuSRef.current.style.transform = "translateX(-50%) translateY(50px)";

                if (scrollTimeout.current) {
                    clearTimeout(scrollTimeout.current);
                }

                scrollTimeout.current = setTimeout(() => {
                    menuSRef.current.style.transform = "translateX(-50%) translateY(0px)";
                }, 500);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (scrollTimeout.current) {
                clearTimeout(scrollTimeout.current);
            }
        };
    })

    //click menu后设置部分变量
    const [menu, setMenu] = useState(false)
    const openMenu = () => {
        setMenu(!menu)
    }

    //click menu后部分动效
    const menuButtonRef = useRef([])
    useEffect(() => {
        if (menu) {
            menuButtonRef.current.forEach((menuButton) => {
                menuButton.style.pointerEvents = "all"
            })
            menuButtonRef.current[0].style.transform = "rotate(15deg) translateX(100px) rotate(-15deg)";
            menuButtonRef.current[1].style.transform = "rotate(185deg) translateX(100px) rotate(-185deg)";
            menuButtonRef.current[2].style.transform = "rotate(-90deg) translateX(100px) rotate(90deg)";
        } else {
            menuButtonRef.current.forEach((menuButton) => {
                menuButton.style.transform = "rotate(-270deg) translateX(100px) rotate(-270deg)"
                menuButton.style.pointerEvents = "none"
            })
        }
    }, [menu])

    return (
        <div className={styles.menuS} ref={menuSRef} onClick={openMenu}>
            <div className={styles.menuButton} style={{ "--deg": `${15}deg` }} ref={(e) => menuButtonRef.current[0] = e} onClick={(e) => props.changePage(e)} data-value={filterPath[0]}>
                {filterPath[0]}
            </div>
            <div className={styles.menuButton} style={{ "--deg": `${185}deg` }} ref={(e) => menuButtonRef.current[1] = e} onClick={(e) => props.changePage(e)} data-value={filterPath[1]}>
                {filterPath[1]}
            </div>
            <div className={styles.menuButton} style={{ "--deg": `${-90}deg` }} ref={(e) => menuButtonRef.current[2] = e} onClick={(e) => props.changePage(e)} data-value="main">
                <svg t="1721818018091" className={styles.icon} viewBox="0 0 1111 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3758" >
                    <path d="M0 555.885714L555.885714 0l555.885715 555.885714h-146.285715v468.114286H146.285714V555.885714z" fill="#c1acd7" p-id="3759" data-spm-anchor-id="a313x.search_index.0.i2.553c3a816b7sQk" ></path>
                    <path d="M409.6 1024V614.4h292.571429v409.6z" fill="#915fc7" p-id="3760" data-spm-anchor-id="a313x.search_index.0.i3.553c3a816b7sQk" ></path>
                </svg>
            </div>
        </div>
    )
}