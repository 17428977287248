import React, { useEffect, useRef } from 'react'; //import React Component
import styles from './Bg.module.css';

export function Bg() {
    //五颜六色
    const cubesRef = useRef([]);
    useEffect(() => {
        cubesRef.current.forEach(li => {
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            li.style.backgroundColor = color;
        });
    }, []);

    return (
        <div className={styles.bg}>
            <ul className={styles.cubes}>
                {Array.from({ length: 10 }).map((li, index) => (
                    <li key={`${index}li`} ref={(e) => cubesRef.current[index] = e}></li>
                ))}
            </ul>
        </div>
    );
}