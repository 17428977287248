import React, { useEffect, useRef, useState } from 'react';
import styles from './ImgGallery.module.css';

export function ImgGallery(props) {
    //设置slide大小
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const slidesRef = useRef(null)
    const imgGalleryRef = useRef(null)
    const slideRef = useRef([])
    const [slideWidth, setSlideWidth] = useState(598);
    const [galleryWidth, setGalleryWidth] = useState(178);
    const [click, setClick] = useState(false)
    const updateSlideWidth = () => {
        setTimeout(() => {
            if (slidesRef.current) {
                setSlideWidth(slidesRef.current.offsetWidth);
                setGalleryWidth(imgGalleryRef.current.offsetWidth)
            }
        }, 300);
    };

    useEffect(() => {
        updateSlideWidth();
        window.addEventListener('resize', updateSlideWidth);
        return () => {
            window.removeEventListener('resize', updateSlideWidth);
        };
    });

    const imgRef = useRef([])
    useEffect(() => {
        if (screenWidth < 600) {
            imgRef.current.forEach((img) => {
                img.style.width = `${galleryWidth}px`
            });
        }
    }, [galleryWidth, screenWidth]);

    //切换
    const switchLeft = (e) => {
        e.stopPropagation();
        if (!click) {
            setClick(true);
            const slides = slidesRef.current;
            const totalSlides = slides.children.length;
            let currentLeft = slides.offsetLeft;
            let newPosition = currentLeft + slideWidth;
            if (newPosition > 0) {
                newPosition = -(slideWidth * (totalSlides - 1));
            }

            slides.style.left = `${newPosition}px`;
            setTimeout(() => {
                setClick(false);
            }, 300)
        }
    };

    const switchRight = (e) => {
        e.stopPropagation();
        if (!click) {
            setClick(true);
            const slides = slidesRef.current;
            const totalSlides = slides.children.length;
            let currentLeft = slides.offsetLeft;
            let newPosition = currentLeft - slideWidth;
            if (newPosition <= -(slideWidth * totalSlides)) {
                newPosition = 0;
            }

            slides.style.left = `${newPosition}px`;
            setTimeout(() => {
                setClick(false);
            }, 300)
        }
    };

    //加载图片
    const nextRef = useRef(null);
    const prevRef = useRef(null);
    const loaderRef = useRef([])
    const handleImageLoad = (index) => {
        setTimeout(() => {
            if (imgRef.current[index]) {
                imgRef.current[index].classList.remove(styles.hiddenImage);
                loaderRef.current[index].classList.add(styles.hiddenLoader);
                nextRef.current.style.pointerEvents = "all"
                prevRef.current.style.pointerEvents = "all"
            }
        }, 300);
    };

    const [div, setDiv] = useState(null)
    useEffect(() => {
        if (props.imgs.length > 0) {
            let div = props.imgs.map((img, index) => {
                return (
                    <div className={styles.slide} key={index} ref={(e) => slideRef.current[index] = e}>
                        <div>
                            <img
                                ref={(e) => imgRef.current[index] = e}
                                src={process.env.PUBLIC_URL + img}
                                alt="img"
                                onLoad={() => handleImageLoad(index)}
                                className={styles.hiddenImage}
                                draggable="false"
                                style={{ "--h": props.height, "--w": props.width }}
                            />
                        </div>
                        <div className={styles.loader} ref={(e) => loaderRef.current[index] = e}>{index}</div>
                    </div>
                )
            })
            setDiv(div)
        }
    }, [props.imgs])

    return (
        <div className={styles.imgGallery} ref={imgGalleryRef}>
            <div
                className={styles.slides}
                ref={slidesRef}
            >
                {div}
            </div>
            <div className={styles.prev} onClick={(e) => switchLeft(e)} ref={prevRef}>❮</div>
            <div className={styles.next} onClick={(e) => switchRight(e)} ref={nextRef}>❯</div>
        </div >
    );
}